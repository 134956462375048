import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const h2Style = "text-xl md:text-2xl font-semibold md:px-12 mt-12 mb-6"
const pStyle = "text-lg md:text-xl md:px-12 mb-6"
const pStyleItalic = "text-lg md:text-xl md:px-12 mb-6 italic"
const listItemStyle = "text-lg md:text-xl md:px-12 mb-4"
const dateStyle = "text-xs md:px-12 mb-6"

const PageBlogPost1 = () => {
  const data = useStaticQuery(graphql`
    query PageBlogPost1Query {
      imgHero: file(relativePath: { eq: "backgrounds/blog-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  return (
    <Layout>
      <Seo
        title="Another Abusive Captive Takes a Tumble in Tax Court"
        description="In a recent ruling, the US Tax Court again concluded that a micro captive wasn’t actually providing insurance."
        image={imgSrc}
      />
      <HeroSection
        title="ANOTHER ABUSIVE CAPTIVE TAKES A TUMBLE IN TAX COURT"
        imgSrc={imgHero}
      />

      <div className="py-20">
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
          <p className={dateStyle}>April 2, 2021</p>
          <p className={pStyleItalic}>SRA Founder & CEO, Van Carlson, discusses the Caylor Land Ruling</p>
          <p className={pStyle}>In a recent ruling, the US Tax Court again concluded that a micro captive wasn’t actually providing insurance. This conclusion isn’t surprising when you consider the facts and as the Judge stated in his summary, “We will break no new ground today.”</p>
          <h2 className={h2Style}>What Went Wrong in Caylor Land v. Commissioner?</h2>
          <p className={pStyle}>In the most recent adverse ruling to an abusive micro captive, Caylor Land & Development, Inc., et al. vs. Commissioner, the Court found that Caylor’s captive, Consolidated, Inc, didn’t actually provide insurance and failed to act as an insurer commonly would. Specifically, Caylor’s captive:</p>
          <ol className="list-decimal list-inside ml-6 mb-6">
            <li className={listItemStyle}>Failing to distribute risk by not joining an insurance pool that their captive manager suggested.</li>
            <li className={listItemStyle}>Failing to distribute risk by concentrating risk exposure in one entity, one geographic area, and one industry.</li>
            <li className={listItemStyle}>Failing to distribute risk in large enough numbers.</li>
            <li className={listItemStyle}>Paying claims without adequate supporting documentation.</li>
            <li className={listItemStyle}>Issuing claims-made policies after the claims period ended.</li>
            <li className={listItemStyle}>Calculating premiums based upon what the insured wanted to pay, rather than having a premium methodology established by a third party.</li>
          </ol>
          <h2 className={h2Style}>Consulting Fees Disallowed</h2>
          <p className={pStyle}>What’s also noteworthy about this case is that the Court disallowed the tax deduction for the consulting fees paid between the related parties. While it’s not unusual for related parties to pay each other consulting fees, the Caylor consulting fees were an optical illusion.</p>
          <p className={pStyle}>No consulting services were constructively provided. The fees were paid so that the related entities could join the captive to give the illusion of risk distribution--evidenced by the consulting fees paid amongst the Caylor entities matching the premium amounts paid to the captive.</p>
          <h2 className={h2Style}>Penalties Assessed</h2>
          <p className={pStyle}>It appears that the Court was also making a point by allowing accuracy-related penalties to be assessed. This is the first captive case where the Court awarded penalties.</p>
          <h2 className={h2Style}>How to Avoid Being the Next Caylor</h2>
          <p className={pStyle}>As the Judge states “The line between nondeductible self-insurance and deductible insurance is blurry, and we try to clarify it by looking to four nonexclusive but rarely supplemented criteria.”</p>
          <ol className="list-decimal list-inside ml-6 mb-6">
            <li className={listItemStyle}><strong>Risk Shifting</strong> - Proper transfer of risk from your company to the insuring company.</li>
            <li className={listItemStyle}><strong>Risk Distribution</strong> - Distribution of risk among unrelated parties.</li>
            <li className={listItemStyle}><strong>Fortuitous Risk</strong> - Insured risk must happen by accident or chance, not ordinary business risk.</li>
            <li className={listItemStyle}><strong>Insurance Principles</strong> - Must operate in a similar manner as an ordinary for-profit insurance company would.</li>
          </ol>
          <p className={pStyle}>This criteria outlined by the Judge has been a practice implemented by SRA for years, we call it our <a href="https://vimeo.com/360875349" target="_blank" rel="noopener noreferrer" className="text-site-red underline">4-Part Test</a>. SRA based this test on previous court cases and would have identified Caylor’s captive as abusive. We find it crucial to follow the 4-Part Test to participate in an 831(b) plan.</p>
          <h2 className={h2Style}>Risk Distribution in 2021</h2>
          <p className={pStyle}>If you have a captive and want to know if you’re at risk of non-compliance, a strong litmus test for risk distribution is to ask yourself this question:</p>
          <p className={pStyleItalic}>"Has my captive participated in claims unrelated to my business?"</p>
          <p className={pStyle}>If you’ve been participating in a captive for several years, the answer should be yes, especially after the COVID-19 pandemic.</p>
          <p className={pStyle}>The pandemic caused hardships and disruptions for nearly every business. Whether it was supply chain interruptions or lost revenue from government-mandated closures, captives insuring these risks saw an unprecedented claim volume in 2020.</p>
          <p className={pStyle}>But suppose your captive didn’t have any pandemic claims or only had claims your business submitted. In that case, it’s time to <Link to="/contact" className="text-site-red underline">have a conversation with SRA</Link> about whether your captive will stand up to IRS scrutiny.</p>
        </div>
      </div>
    </Layout>
  )
}

export default PageBlogPost1
